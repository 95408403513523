var render = function render(){var _vm=this,_c=_vm._self._c;return _c('HXContentCard',{attrs:{"title":"Listado de inspecciones","toBack":{ name: 'InspeccionVehiculo' }}},[_c('div',{staticClass:"px-5 py-3"},[_c('div',{staticClass:"row",staticStyle:{"gap":"1rem"}},[_c('div',{staticClass:"col-12 col-md-10"},[_c('CardTable',{attrs:{"data":_vm.dataCards}})],1),_c('div',{staticClass:"col-12 col-md"},[_c('BtnAddCardTable',{attrs:{"text":"Nueva Inspección"},on:{"click":function($event){return _vm.$router.push({
              name: 'ContentFormInspeccionNew',
              params: {
                placa: _vm.$route.params.placa,
                vehiculoid: _vm.$route.params.vehiculoid,
              },
            })}}})],1)])]),_c('div',{staticClass:"table-responsive px-4 mt-3"},[_c('table',{staticClass:"table table-bordered table-responsive-md table-striped text-center",attrs:{"id":"datatable_listInspecciones"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Fecha inicio")]),_c('th',[_vm._v("Fecha final")]),_c('th',[_vm._v("Estado")]),_c('th',[_vm._v("Opciones")])])]),_c('tbody',_vm._l((_vm.listInpecciones),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.FechaInicio))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.FechaFinal))+" ")]),_c('td',[_c('h4',[_c('b-badge',{attrs:{"variant":`${
                  item.Estado === 'Finalizada' ? 'success' : 'primary'
                }`}},[_c('i',{class:`fas ${
                    item.Estado === 'Finalizada' ? 'fa-check' : 'fa-clock'
                  }`}),_vm._v("  "+_vm._s(item.Estado)+" ")])],1)]),_c('td',[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('VueToggles',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Finalizar","height":"20","width":"50","disabled":item.Estado === 'Finalizada',"value":item.Estado === 'Finalizada'},on:{"click":function($event){return _vm.finalizarInspeccion(index, item)}}}),_c('vs-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Editar","danger":"","disabled":item.Estado === 'Finalizada'},on:{"click":function($event){return _vm.$router.push({
                    name: 'ContentFormInspeccionEdit',
                    params: {
                      idinspeccion: item.IdInspeccion,
                    },
                  })}}},[_c('i',{staticClass:"fas fa-pen"})]),_c('vs-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Visualizar","warn":""},on:{"click":function($event){return _vm.$router.push({
                    name: 'InspeccionDetails',
                    params: {
                      idinspeccion: item.IdInspeccion,
                    },
                  })}}},[_c('i',{staticClass:"fas fa-file-lines"})])],1)])])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }