<script>
import moment from "moment";
import "moment/locale/es";
moment.locale("es");
import { core } from "../../../config/pluginInit";
import CardTable from "@/components/cardTable/CardTable.vue";
import BtnAddCardTable from "@/components/btnCardTable/btnAddCardTable.vue";

const Swal = require("sweetalert2");
export default {
  async mounted() {
    this.$isLoading(true);
    this.listInpecciones = await this.$store.dispatch(
      "getListInspeccionesByVehiculoId",
      this.$route.params.vehiculoid
    );
    setTimeout(() => {
      core.index();
      if (window.$.fn.DataTable.isDataTable("#datatable_listInspecciones")) {
        window.$("#datatable_listInspecciones").DataTable().destroy();
      }
      this.dataTableInstance = window
        .$("#datatable_listInspecciones")
        .DataTable({
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
          },
        });
    }, 0);
    this.$isLoading(false);
  },
  components: {
    CardTable,
    BtnAddCardTable,
  },
  data() {
    return {
      listInpecciones: [],
      dataCards: [
        {
          title: "Placa del vehículo",
          value: () => this.$route.params.placa.toUpperCase(),
          sizeValue: 30,
          iconName: "car",
          color: "rgb(255, 130, 0)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },
        {
          title: "Finalizadas",
          value: () =>
            this.listInpecciones.filter((item) => item.Estado !== "En Proceso")
              .length,
          iconName: "check-circle",
          color: "#17a76c",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.dataTableInstance.search("Finalizada").draw();
          },
          onDeactivate: () => {
            console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },

        {
          title: "Total inspecciones",
          value: () => this.listInpecciones.length,
          iconName: "file-signature",
          color: "rgb(0, 123, 146)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },
      ],
    };
  },
  methods: {
    async finalizarInspeccion(rowIndex, item) {
      try {
        Swal.fire({
          title: "¿Estas seguro?",
          text: "Después de finalizarla no podras revertir los cambios!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#1A5CFF",
          cancelButtonColor: "rgb(242, 19, 67)",
          confirmButtonText: "Si, finalizar!",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const nuevaFechaFinal = this.$moment
              .tz(new Date(), "America/Bogota")
              .format("YYYY-MM-DDTHH:mm");

            // actualizar la fecha de finalización en la base de datos
            const response = await this.$store.dispatch("patch", {
              path: `RevisionPeriodicaVehiculo/ChangeFechaFinalizacion/${item.IdInspeccion}`,
              data: nuevaFechaFinal,
            });

            if (response) {
              this.$set(this.listInpecciones, rowIndex, {
                ...item,
                FechaFinal: nuevaFechaFinal,
                Estado: "Finalizada",
              });
              setTimeout(() => {
                this.dataTableInstance.row(rowIndex).invalidate().draw();
              }, 100);
              Swal.fire("Listo!", "Se finalizó la inspección.", "success");
            } else {
              Swal.fire(
                "Error!",
                "Ha ocurrido un error,por favor intenta nuevamente.",
                "error"
              );
            }
          }
        });
      } catch (error) {
        console.log("Error al finalizar la inspección:", error);
        return null;
      }
    },
  },
  filters: {
    formatDateTime(dateString) {
      return dateString
        ? moment(dateString).format("MMM D, YYYY h:mm A")
        : "...";
    },
  },
};
</script>

<template>
  <HXContentCard
    title="Listado de inspecciones"
    :toBack="{ name: 'InspeccionVehiculo' }"
  >
    <div class="px-5 py-3">
      <div class="row" style="gap: 1rem">
        <div class="col-12 col-md-10">
          <!-- linar-style cursor glow -->
          <CardTable :data="dataCards" />
        </div>
        <!-- btn nueva inspeccion -->
        <div class="col-12 col-md">
          <BtnAddCardTable
            text="Nueva Inspección"
            @click="
              $router.push({
                name: 'ContentFormInspeccionNew',
                params: {
                  placa: $route.params.placa,
                  vehiculoid: $route.params.vehiculoid,
                },
              })
            "
          />
        </div>
      </div>
    </div>

    <div class="table-responsive px-4 mt-3">
      <table
        id="datatable_listInspecciones"
        class="table table-bordered table-responsive-md table-striped text-center"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Fecha inicio</th>
            <th>Fecha final</th>
            <th>Estado</th>
            <th>Opciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in listInpecciones" :key="index">
            <td>{{ index + 1 }}</td>
            <td>
              {{ item.FechaInicio | formatDateTime }}
            </td>
            <td>
              {{ item.FechaFinal | formatDateTime }}
            </td>
            <td>
              <h4>
                <b-badge
                  :variant="`${
                    item.Estado === 'Finalizada' ? 'success' : 'primary'
                  }`"
                >
                  <i
                    :class="`fas ${
                      item.Estado === 'Finalizada' ? 'fa-check' : 'fa-clock'
                    }`"
                  ></i
                  >&nbsp;
                  {{ item.Estado }}
                </b-badge>
              </h4>
            </td>
            <td>
              <div class="d-flex align-items-center justify-content-center">
                <!-- finalizar inspeccion -->
                <VueToggles
                  v-b-tooltip.hover
                  title="Finalizar"
                  height="20"
                  width="50"
                  :disabled="item.Estado === 'Finalizada'"
                  :value="item.Estado === 'Finalizada'"
                  @click="finalizarInspeccion(index, item)"
                />
                <!-- editar inspeccion -->
                <vs-button
                  v-b-tooltip.hover
                  title="Editar"
                  danger
                  :disabled="item.Estado === 'Finalizada'"
                  @click="
                    $router.push({
                      name: 'ContentFormInspeccionEdit',
                      params: {
                        idinspeccion: item.IdInspeccion,
                      },
                    })
                  "
                >
                  <i class="fas fa-pen"></i
                ></vs-button>
                <!-- ver detalles inspeccion -->
                <vs-button
                  v-b-tooltip.hover
                  title="Visualizar"
                  warn
                  @click="
                    $router.push({
                      name: 'InspeccionDetails',
                      params: {
                        idinspeccion: item.IdInspeccion,
                      },
                    })
                  "
                >
                  <i class="fas fa-file-lines"></i
                ></vs-button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </HXContentCard>
</template>
